<template>
    <div class="p-info-content">
        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
            <div class="section-title margin-deliver">{{ $t("Educational Qualification") }}</div>
            <router-link class="edit-button" :to="{ name: 'candidate.qualifications.create'}">
                <span class="edit-icon"><i class="eicon e-plus"></i></span>
                <span>{{ $t("academic qualification") }}</span>
            </router-link>
        </div>
        <template v-if="isContentLoading">
            <div class="educational-qualification row row-cols-md-2">
                <div class="col"  v-for="index in 4" :key="index">
                    <profile-card-loader></profile-card-loader>
                </div>
            </div>
        </template>
        <template v-else>
            <h4 class="empty-message" v-if="isEmpty">{{ $t("no educational qualification found") }}</h4>
            <div class="educational-qualification" v-else>
                <draggable :list="educations" class="row" ghost-class="ghost" chosen-class="chosen" drag-class="drag" @end="sortEducationQualification">
                    <div class="col-md-6 align-items-stretch" draggable="true" @dragstart="startEducationQualificationDrag" @dragend="endEducationQualificationDrag" v-for="(education, index) in educations" :key="index">
                    <div class="p-info-card form-box">
                        <div class="p-info-icon">
                            <i class="eicon e-graduation"></i>
                        </div>
                        <div class="p-info-text">
                            <h5> {{ education.degree }}</h5>
                            <ul>
                                <li>{{ education.academy_name }}</li>
                                <li><strong>{{ $t("Level of Education") }}:</strong> {{ $t(education.level) }}</li>
                                <li><strong>{{ $t("Year of passing") }}:</strong> {{ education.passing_year }}</li>
                            </ul>
                        </div>
                        <div class="p-info-control">
                            <a href="javascript:void(0)" class="control-button control-button--info has_bg_color"
                               @click="editEducation(education.id)">
                                <div class="control-button__icon">
                                    <i class="eicon e-pencil"></i>
                                </div>
                                <span>{{ $t("Edit") }}</span>
                            </a>
                            <a href="javascript:void(0)" class="control-button control-button--danger has_bg_color"
                               @click="deleteEducation(education.id)">
                                <div class="control-button__icon">
                                    <i class="eicon e-delete"></i>
                                </div>
                                <span>{{ $t("Delete") }}</span>
                            </a>
                        </div>
                    </div>
                </div>
                </draggable>
            </div>
        </template>

    </div>
</template>

<script>
import ProfileCardLoader from "../../../../components/_loaders/ProfileCardLoader";
import {getCandidateEducation} from "../../../../app/api/CommonRequest";
import client from "../../../../app/api/Client";
import draggable from "vuedraggable";

export default {
    name: "List",
    components: {
        ProfileCardLoader,
        draggable
    },
    data() {
        return {
            isContentLoading: true,
            educations: [],
        }
    },
    computed: {
        isEmpty() {
            return this.educations.length === 0;
        },
    },
    methods: {
        async getEducation() {
            this.isContentLoading = true;
            try {
                let {data} = await getCandidateEducation();
                this.educations = data;

            } catch (e) {
            }
            this.isContentLoading = false;
        },
        editEducation(educationId) {
            this.$router.push({
                name: 'candidate.qualifications.edit', params: {id: educationId}
            });
        },
        deleteEducation(educationId, index) {
            let message = {
                title: this.$t('confirmation'),
                body: this.$t('do you really want to delete this educational qualification?')
            };
            this.$dialog.confirm(message).then(() => {
                client().delete(`/candidate/education/${educationId}/delete`)
                    .then(({data: {data, message}}) => {
                        this.educations.splice(index, 1);
                        this.$toast.success(this.$t(message));
                    }).catch(error => {
                });
            });
        },
        async sortEducationQualification(evt) {
            client().post(`candidate/education/re-order`, {'educations': this.educations})
                .then(response => {

                }).catch(error => {
                });
        },
        startEducationQualificationDrag(evt) {
            let elem = evt.target;

            setTimeout(() => {
                elem.style.opacity = 0;
            }, 0);
        },
        endEducationQualificationDrag(evt) {
            let elem = evt.target;
            elem.style.opacity = 1;
        },
    },
    mounted() {
        this.getEducation();
    }
}
</script>

<style scoped>
    .chosen {
        opacity: 0.5;
    }

    .chosen.ghost {
        opacity: 0;
    }

    .drag {
        opacity: 0.5;
    }
    .form-box {
        padding: 50px 25px;
    }
    .educational-qualification {
        display: grid;
    }
    .educational-qualification .p-info-text ul {
        list-style: none;
    }
</style>
